import { Slider } from "antd";
import { SliderMarks } from "antd/lib/slider";
import * as React from "react";
import { connect } from "react-redux";

import { CustomCSS, TriggerType } from "@connect/Interfaces";
import { TriggerDurations, TriggerThresholds } from "Components/Actions/Constants";
import { Icon } from "Components/Global/Common";
import HelpPopover from "Components/Global/HelpPopover";
import { updateSelectedTriggerDuration, updateSelectedTriggerThreshold } from "Data/Actions/ActionsAsync";
import { ActionType } from "Data/Objects/ActionTypes";
import {
	getActionHasAd, getSelectedActionType,
	getSelectedTriggerDuration, getSelectedTriggerThrottle,
	getSelectedTriggerType,
	getSelectedTriggerContinuous
} from "Data/Selectors/ActionBuilder";
import ContinuousProperties from "Components/Actions/ContinuousProperties";

interface DurationThresholdPropertiesProps {
	duration: number;
	threshold: number;
	updateDuration: (value: number) => void;
	updateThreshold: (value: number) => void;
	actionType: ActionType;
	hasAd: boolean;
	triggerType: TriggerType;
	continuous: boolean;
}

const mapStateToProps = (state) => {
	return {
		actionType: getSelectedActionType(state),
		hasAd: getActionHasAd(state),
		triggerType: getSelectedTriggerType(state),
		duration: getSelectedTriggerDuration(state),
		threshold: getSelectedTriggerThrottle(state),
		continuous: getSelectedTriggerContinuous(state)
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateDuration: (duration: number) => dispatch(updateSelectedTriggerDuration(TriggerDurations[duration])),
		updateThreshold: (threshold: number) => dispatch(updateSelectedTriggerThreshold(TriggerThresholds[threshold]))
	}
}

class DurationThresholdProperties extends React.PureComponent<DurationThresholdPropertiesProps> {
	constructor(props: DurationThresholdPropertiesProps) {
		super(props);

		this.styles = {
			label: {
				display: "inline-block"
			},
			mark: {
				marginTop: 4
			},
			row: {
				margin: "10px 0px 7px"
			},
			slider: {
				margin: "0px 10px 7px 10px"
			}
		};

		this.renderAlarmContinuousProperties = this.renderAlarmContinuousProperties.bind(this);
	}

	styles: CustomCSS;

	render() {
		const { hasAd, duration, threshold, updateDuration, updateThreshold, continuous } = this.props;
		const { label, row, slider } = this.styles;
		// translate durations to marks on the slider
		const getMark = (value: number) => {
			return TriggerDurations.indexOf(value);
		}

		const getThresholdMark = (value: number) => {
			return TriggerThresholds.indexOf(value);
		}

		// ant slider requires marks; if we use the seconds, the marks
		// aren't symmetrical and will be at 5, 10, 15, and 30 seconds
		const marks = TriggerDurations.reduce((obj: SliderMarks, seconds: number) => ({
			...obj, [getMark(seconds)]: {
				label: seconds + " sec",
				style: this.styles.mark
			}
		}), {});

		const thresholdMarks = TriggerThresholds.reduce((obj: SliderMarks, seconds: number) => ({
			...obj, [getThresholdMark(seconds)]: {
				label: seconds + " sec",
				style: this.styles.mark
			}
		}), {});

		return (
			<React.Fragment>
				<div style={ row }>
					<div style={ label }><Icon name="clock" /> Set Action Duration:</div>
					<HelpPopover title="Action Duration">
						<p>
								Adjust the amount of time the Event Action(s) will run once the trigger is activated.<br />
								If an Ad is selected, it will play the duration of the ad.
						</p>
					</HelpPopover>
					<div style={ slider }>
						<Slider
							key={ duration }
							disabled={ hasAd || continuous }
							tipFormatter={ null }
							step={ null }
							marks={ marks }
							min={ 0 }
							max={ 3 }
							defaultValue={ getMark(duration) }
							onAfterChange={ updateDuration } />
					</div>
				</div>
				<br />
				{ this.renderAlarmContinuousProperties() }
				<div style={ row } key={ threshold }>
					<div style={ label }><Icon name="clock" /> Set Action Threshold:</div>
					<HelpPopover title="Action Threshold">
						<p>
								Adjust the amount of time that must pass until the Event Action(s) can be triggered again.
						</p>
					</HelpPopover>
					<div style={ slider }>
						<Slider
							tipFormatter={ null }
							step={ null }
							marks={ thresholdMarks }
							min={ 0 }
							max={ 5 }
							defaultValue={ getThresholdMark(threshold) }
							onAfterChange={ updateThreshold } />
					</div>
				</div>
			</React.Fragment>
		);
	}

	renderAlarmContinuousProperties() {
		const { triggerType } = this.props;

		if (triggerType === "alarm_0" || triggerType === "alarm_1" || triggerType === "network_request") {
			return <ContinuousProperties />;
		}

		return null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DurationThresholdProperties);
